import Row from "react-bootstrap/Row";
import React, { useContext } from "react";
import productImage from "../../assets/images/pro1.jpg";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { OrderContext } from '../../context/orders';

const authUser = () => {
  const user = localStorage.getItem("isLoggedIn");
  return user;
};

function OrderDetailContent() {
  let isAuthUser = authUser();
  const [orderDetails, setOrderDetails] = React.useState([]);
  const navigate = useNavigate();
  let { orderId } = useParams();
  const [returnIds, setreturnIds] = React.useState([]);
  const {storeId, setStoreId} = useContext(OrderContext);
  const [reasons, setReasons] = React.useState({});
  const [customReasons, setCustomReasons] = React.useState({});
  const [isCheckedStoreReturn, setIsCheckedStoreReturn] =React.useState({});
  React.useEffect(() => {
    if (!storeId) {
      const storedStoreId = localStorage.getItem("storeid");
      if (storedStoreId) {
        setStoreId(storedStoreId);
      }
    }
  }, [storeId, setStoreId]);
  const notifyscucess = (errorMessage) => toast.success(errorMessage);
  const notifyerror = (successMessage) => toast.error(successMessage);

  const handleReturnButtonClick = async () => {
    const fulfillmentlineitem = document.querySelectorAll(
      'input[type="checkbox"][name="fulfillmentlineitem"]:checked'
    );
    if (!fulfillmentlineitem || fulfillmentlineitem.length === 0) {
      alert("No checkboxes are checked! Please check at least one.");
      return;
    }
    console.log(isCheckedStoreReturn);
    const returndata = Array.from(fulfillmentlineitem).map((checkbox) => {
      const fulfillmentlineitemid = checkbox.value;
      const productQtyInput = checkbox
        .closest("tr")
        .querySelector('input[name="productqty"]');
      const productqty = productQtyInput ? productQtyInput.value : "";
      const returnreason = reasons[fulfillmentlineitemid] ? reasons[fulfillmentlineitemid] : "";
      const returnnote = customReasons[fulfillmentlineitemid] ? customReasons[fulfillmentlineitemid] : "";
      const storereturn = isCheckedStoreReturn[fulfillmentlineitemid] ? isCheckedStoreReturn[fulfillmentlineitemid] : false;
      return { fulfillmentlineitemid, productqty, returnreason, returnnote, storereturn };
    });

    axios({
      method: "post",
      url: `${process.env.REACT_APP_STORE_URL}/orders/order-detail/returncreate`,
      data: {
        orderId: orderId,
        fulfillmentlineitemids: returndata,
        warehouseId: storeId,
      },
    })
      .then((response) => {
        if (
          response.data.returnresult.returnRequest.userErrors &&
          response.data.returnresult.returnRequest.userErrors.length > 0
        ) {
          const errorMessage =
            response.data.returnresult.returnRequest.userErrors[0].message;
          notifyerror(errorMessage);
        } else {
          if (response.data.returnsdetails.length > 0) {
            let returnsdetails = response.data.returnsdetails[0];
            setreturnIds([
              ...returnIds,
              {
                [returnsdetails.returnfulfillmentLineItem]:
                  returnsdetails.productReturnId,
              },
            ]);
            handleCheckBox();
          }
          notifyscucess("Success: Return updated successfully");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        notifyerror("Error: Failed to update data. Please try again.");
      });
  };

  const handleApproveReturnButtonClick = async (fulfillmentLineItemId) => {

    const isConfirmed = window.confirm("Êtes-vous sur de vouloir accepter ce retour ?");
    if (!isConfirmed) {
      return; 
    }
    const fulfillmentlineitem = document.querySelectorAll(
      'input[type="checkbox"][name="fulfillmentlineitem"]:checked'
    );
    if (!fulfillmentlineitem || fulfillmentlineitem.length === 0) {
      alert("No checkboxes are checked! Please check at least one.");
      return;
    }
    const returnId = returnIds.find((returnId) =>
      returnId.hasOwnProperty(fulfillmentLineItemId)
    );
    if (!returnId) {
      alert(
        "No return ID found for the selected fulfillmentLineItemId:",
        fulfillmentLineItemId
      );
      return;
    }
    const selecteddata = Array.from(fulfillmentlineitem).map((checkbox) => {
      const fulfillmentlineitemid = checkbox.value;
      const productQtyInput = checkbox
        .closest("tr")
        .querySelector('input[name="productqty"]');
      const productqty = productQtyInput ? productQtyInput.value : "";
      const returnreason = customReasons[fulfillmentlineitemid] ? customReasons[fulfillmentlineitemid] : "";
      return { fulfillmentlineitemid, productqty,returnreason };
    });
     //console.log(returnId);
    // return;

    let employeeId = 0;
    if (localStorage.getItem("employeeid") !== null) {
      employeeId = localStorage.getItem("employeeid");
    }

    axios({
      method: "post",
      url: `${process.env.REACT_APP_STORE_URL}/orders/order-detail/returnaccept`,
      data: {
        orderId: orderId,
        fulfillmentlineitemids: selecteddata,
        returnId: returnId[fulfillmentLineItemId],
        warehouseId: storeId,
        employeeId: employeeId,
      },
    })
      .then((response) => {
        if (
          response.data.returnresult.returnApproveRequest.userErrors &&
          response.data.returnresult.returnApproveRequest.userErrors.length > 0
        ) {
          const errorMessage =
            response.data.returnresult.returnApproveRequest.userErrors[0].message;
          notifyerror(errorMessage);
        } else {
          // if (response.data.returnsdetails.length > 0) {
          //   let returnsdetails = response.data.returnsdetails[0];
          //   console.log(returnsdetails);
          //   setreturnIds([
          //     ...returnIds,
          //     {
          //       [returnsdetails.returnfulfillmentLineItem]:
          //         returnsdetails.productReturnId,
          //     },
          //   ]);
          //   handleCheckBox();
          // }
          const newReturnIds = returnIds.filter((returnIds) => {
            if (!(fulfillmentLineItemId in returnIds)) {
              return returnIds;
            }
          });
          setreturnIds((prevReturnIds) => [...newReturnIds]);
          handleCheckBox();
          notifyscucess("Success: Return updated successfully");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        notifyerror("Error: Failed to update data. Please try again.");
      });
  };

  const handleCancelButtonClick = async (fulfillmentLineItemId) => {

    const isConfirmed = window.confirm("Êtes-vous sûr de vouloir refuser ce retour?");
    if (!isConfirmed) {
      return; 
    }
    const fulfillmentlineitem = document.querySelectorAll(
      'input[type="checkbox"][name="fulfillmentlineitem"]:checked'
    );
    if (!fulfillmentlineitem || fulfillmentlineitem.length === 0) {
      alert("No checkboxes are checked! Please check at least one.");
      return;
    }
    const returnId = returnIds.find((returnId) =>
      returnId.hasOwnProperty(fulfillmentLineItemId)
    );
    if (!returnId) {
      alert(
        "No return ID found for the selected fulfillmentLineItemId:",
        fulfillmentLineItemId
      );
      return;
    }
    const selecteddata = Array.from(fulfillmentlineitem).map((checkbox) => {
      const fulfillmentlineitemid = checkbox.value;
      const productQtyInput = checkbox
        .closest("tr")
        .querySelector('input[name="productqty"]');
      const productqty = productQtyInput ? productQtyInput.value : "";
      const returnreason = customReasons[fulfillmentlineitemid] ? customReasons[fulfillmentlineitemid] : "";
      const storereturn = isCheckedStoreReturn[fulfillmentlineitemid] ? isCheckedStoreReturn[fulfillmentlineitemid] : false;
      return { fulfillmentlineitemid, productqty, returnreason, storereturn };
    });

    let employeeId = 0;
    if (localStorage.getItem("employeeid") !== null) {
      employeeId = localStorage.getItem("employeeid");
    }

    axios({
      method: "post",
      url: `${process.env.REACT_APP_STORE_URL}/orders/order-detail/returncancel`,
      data: {
        orderId: orderId,
        fulfillmentlineitemids: selecteddata,
        returnId: returnId[fulfillmentLineItemId],
        warehouseId: storeId,
        employeeId: employeeId,
      },
    })
      .then((response) => {
        console.error(response);
        if (
          response.data.returnresult.returnDeclineRequest.userErrors &&
          response.data.returnresult.returnDeclineRequest.userErrors.length > 0
        ) {
          const errorMessage =
            response.data.returnresult.returnDeclineRequest.userErrors[0].message;
          notifyerror(errorMessage);
        } else {
          const newReturnIds = returnIds.filter((returnIds) => {
            if (!(fulfillmentLineItemId in returnIds)) {
              return returnIds;
            }
          });
          setreturnIds((prevReturnIds) => [...newReturnIds]);
          handleCheckBox();
          notifyscucess("Success: Return updated successfully");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        notifyerror("Error: Failed to update data. Please try again.");
      });
  };

  const fetchorderData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_STORE_URL}/orders/order-detail/${orderId}`
    );
    if (response.data.orders) {
      setOrderDetails(response.data.orders);
    }
    console.log(response.data.orders)
    if (response.data.returnsdetails.length > 0) {
      const returnsdetails = response.data.returnsdetails.map(
        (returnsdetails) => ({
          [returnsdetails.returnfulfillmentLineItem]:
            returnsdetails.productReturnId,
        })
      );
      setreturnIds((prevReturnIds) => [...prevReturnIds, ...returnsdetails]);
      handleCheckBox();
      //setreturnIds([returnsdetails]);
    }
  };

  React.useEffect(() => {
    
  }, [returnIds]);

  React.useEffect(() => {
    if (isAuthUser) {
      navigate(`/order-detail/${orderId}`);
    } else {
      navigate("/login");
    }
    fetchorderData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthUser, orderId]);

  const checkReturn = (fulfillmentLineItemId) => {
    return returnIds.some((returnId) =>
      returnId.hasOwnProperty(fulfillmentLineItemId)
    );
  };

  const handleCheckBox = () => {
    let orderDetailsCheckBox = document.querySelectorAll(".order-checkbox");
    orderDetailsCheckBox.forEach((elem) => {
      elem.checked = false;
    });
  };

  const handleOnChange = (event, fulfillmentLineItemId) => {
    setIsCheckedStoreReturn((prevStorereturn) => ({
      ...prevStorereturn,
      [fulfillmentLineItemId]: event.target.checked,
    }));
  };
  //console.log(isCheckedStoreReturn);

  const handleReasonChange = (event, fulfillmentLineItemId) => {
    const { value } = event.target;
    setReasons((prevReasons) => ({
      ...prevReasons,
      [fulfillmentLineItemId]: value,
    }));
    setCustomReasons(prevState => ({
      ...prevState,
      [fulfillmentLineItemId]: value,
    }));
  };
  return (
    <Row className="order-detail-content ">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="dark"
      />
      {orderDetails.map((order) => (
        <>
          <div className="table-responsive p-0">
            <table className="ordered-by ">
              <tbody className="">
                <tr key={order.id_order}>
                  <td className="text-center p-3">
                    <label> ID commande :</label> {order.id_order}
                  </td>
                  <td className="text-center p-3">
                    <label> Nom et prénom : </label> {order.customername}
                  </td>
                  <td className=" text-center p-3">
                    <label> Référence : </label> {order.reference}
                  </td>
                  <td className="text-center p-3">
                    <label> Type de paiement : </label> {order.payment}
                  </td>
                  <td className="text-center  p-3">
                    <label> Date :</label> {order.added_date}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-responsive p-0">
            <table className="">
              <thead>
                <tr className="align-items-center">
					<th className="p-2"></th>
					<th className="p-2">ID Produit</th>
					<th className="p-2 min-width-25">Nom du Produit</th>
					<th className="p-2 min-width-25">Sku</th>
					<th className="p-2">Quantité</th>
					<th className="p-2">Total</th>
					{!String(order.payment).toLowerCase().includes("shopping") && (
						<th className="p-2">Retour magasin</th>
					)}
					<th className="p-2 text-center">Reason</th>
					<th className="p-2 text-center">Action</th>
                </tr>
              </thead>
              <tbody className="">
                {order.orderProducts.map((product, index) => (
                  <tr
                    key={index}
                    className="align-items-center"
                  >
                    <td className="p-2 justify-content-around d-flex align-items-center ">
                      <input
                        type="checkbox"
                        name="fulfillmentlineitem"
                        className="order-checkbox"
                        value={product.fulfillmentLineItemId}
                      />
                      <img
                        className="img-fluid p-0 m-10"
                        src={product.productImg}
                        alt="productImage"
                        width="100%"
                        height="auto"
                        style={{ maxWidth: "60px" }}
                      />
                    </td>
                    <td className="p-2">{product.productId}</td>
                    <td className="carrier min-width-25 p-3">
                      {product.productName}
                    </td>
                    <td className="carrier min-width-25 p-3">
                      {product.productSku}
                    </td>
                    <td className="p-3">
                      <input
                        type="hidden"
                        name="productqty"
                        value={product.productQty}
                      />
                      {product.productQty}
                    </td>
                    <td className="p-2">{product.productPrice} €</td>
					{!String(order.payment).toLowerCase().includes("shopping") && (
						<td className="p-2">
							<input
							type="checkbox"
							name="storereturn"
							checked={isCheckedStoreReturn[product.fulfillmentLineItemId] || false} // Fix: Ensure false when undefined
							onChange={(e) => handleOnChange(e, product.fulfillmentLineItemId)}
							/>
					  	</td>
					)}
                    <td className="date p-2">
                      {(product.returnstatus !== "OPEN") ? (
                          (!checkReturn(product.fulfillmentLineItemId) && product.returnstatus !== "REQUESTED") ? (
                            <select
                              name="reason"
                              className="return-reason"
                              onChange={(e) => handleReasonChange(e, product.fulfillmentLineItemId)}
                              value={reasons[product.fulfillmentLineItemId] || ""}
                            >
                              <option value="">Sélectionner une raison</option>
                              <option value="Inconnue">Inconnue</option>
                              <option value="Taille trop petite">Taille trop petite</option>
                              <option value="Taille trop grande">Taille trop grande</option>
                              <option value="Le client a changer d’avis">Le client a changer d’avis</option>
                              <option value="Le produit de ne correspond pas à la description">Le produit de ne correspond pas à la description</option>
                              <option value="Le produit reçu n’est pas le bon">Le produit reçu n’est pas le bon</option>
                              <option value="Produit abîmé ou défectueux">Produit abîmé ou défectueux</option>
                              <option value="Pas reçu d’étiquette de retour">Pas reçu d’étiquette de retour</option>
                              <option value="Style">Style</option>
                              <option value="Couleur">Couleur</option>
                            </select>
                          ) : (
                            <span className="rounded-pill w-auto px-3 background-none mt-2">
                                {product.returnreason}
                              </span>
                          )
                      ) : (
                        <span className="rounded-pill w-auto px-3 background-none mt-2">
                          {product.returnreason}
                        </span>
                      )}
                    </td>
                    <td className="date p-2 text-center ">
                      {(product.returnstatus !== "OPEN") ? (
                        (!checkReturn(product.fulfillmentLineItemId) && product.returnstatus !== "REQUESTED") ? (
                          <span
                            className="btn rounded-pill w-auto px-3 background-none border-blue mt-2"
                            onClick={handleReturnButtonClick}
                          >
                            Traiter le retour
                          </span>
                        ) : (
                          <>
                            <span
                              className="btn rounded-pill w-auto px-3 background-none border-blue mt-2"
                              onClick={() => handleApproveReturnButtonClick(product.fulfillmentLineItemId)}
                            >
                              Accepter
                            </span>
                            <span
                              className="btn rounded-pill w-auto px-3 background-none border-blue mx-1 mt-2"
                              onClick={() => handleCancelButtonClick(product.fulfillmentLineItemId)}
                            >
                              Refused
                            </span>
                          </>
                        )
                      ) : (
                        product.returnstatus === "DECLINED" ? "REFUSÉ" : "TERMINÉ"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ))}
      <button
        type="button"
        className="btn btn-primary m-4 rounded-pill w-auto p-20 border-coupon blue-background"
        onClick={() => window.history.back()}
      >
        Retour
      </button>
    </Row>
  );
}

export default OrderDetailContent;
