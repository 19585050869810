import React, { useContext, useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import { Header, NavLinks, ListOrders } from "../common"
import { Route, Routes, useNavigate,  } from 'react-router-dom';
import axios from 'axios';
import OrderDetailContent from "./orderDetailContent"
import { OrderContext } from '../../context/orders';

const authUser = () => {
  const user = localStorage.getItem("isLoggedIn");
  return user;
};

const employeeExists = () => {
  const employeeid = localStorage.getItem("employeeid");
  return employeeid;
}

function Allorders() {

  let isAuthUser = authUser();
  let isEmployeeExist = employeeExists();
  const [ordersData, setOrdersData] = React.useState([]);
  const [pagination, setPagination] = React.useState({});
  const navigate = useNavigate();
  let {storeId, setStoreId} = useContext(OrderContext);
  const [loading, setLoading] = useState(true);
  const [itemsPerPage] = useState(100);
      useEffect(() => {
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      }, []);
  const fetchData = async () => {
      setLoading(true);
      if (!storeId) {
        storeId = localStorage.getItem("storeid");
        if (storeId) {
          setStoreId(storeId);
        }
      }
      console.log(storeId);
      const response = await axios.post(`${process.env.REACT_APP_STORE_URL}/orders/list`,{ storeId, itemsPerPage }); 
      if(response.data.orders) {
        setOrdersData(response.data.orders);
        setPagination(response.data.pagination);
         setLoading(false);
      }
  };
  
  React.useEffect(() => {
    isAuthUser ? navigate("/") : navigate("/login");
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthUser, isEmployeeExist]);

  React.useEffect(() => {
    if(!isEmployeeExist) {
      localStorage.removeItem("isLoggedIn")
      navigate("/login")
    } else {
      navigate("/")
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmployeeExist]);

  return (
    <Row className="allorder m-0">
      <Header />
      {loading ? <div className="loader">
      <div className="spinner"></div>
      </div>  : ''}
      <Row className="flex-wrap mainsection">
        <div className="col-md-2 row-cols-12 left-panel p-0">
          <NavLinks/>
        </div>
        <div className="col-md-10 align-items-center row-cols-12 p-0">
          <h2 className="h2 p-4 m-0">COMMANDES</h2>
            <Routes>
              <Route path='/' element={<ListOrders ordersData={ordersData} pagination={pagination} />} />
              <Route path='/order-detail/:orderId' element={<OrderDetailContent />} />
            </Routes>
        </div>
      </Row>
    </Row>
  );
}

export default Allorders;