import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { OrderContext } from '../../context/orders';
import { debounce } from 'lodash';

export default function ListOrders({ ordersData: initialOrdersData, pagination }) {

    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(25);
    const [pageInfo, setPageInfo] = useState(pagination);
    const [ordersData, setOrdersData] = useState(initialOrdersData);
    let {storeId, setStoreId} = useContext(OrderContext);

    const navigate = useNavigate();
    const currLocation = useLocation();
    const currPath = currLocation.pathname.replace("/", "");

    const handleSearch = (query) => {
        setSearchQuery(query);
    };

    useEffect(() => {
        if(searchQuery) {
            const handler = setTimeout(() => {
                fetchOrdersWithCursor(null, null, searchQuery);
                setCurrentPage(1);
            }, 1000);
            return () => {
                clearTimeout(handler);
            };
        }
    }, [searchQuery]);
    
    const handleChange = (event) => {
        const query = event.target.value;
        handleSearch(query);
    };
    
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch(searchQuery);
        }
    };
    
    const handlePaste = (event) => {
        event.preventDefault();
        const pastedText = (event.clipboardData || window.clipboardData).getData('text');
        handleSearch(pastedText);
    };
    
    const goToOrderDetail = (orderId) => {
        navigate(`/order-detail/${orderId}`);
    };

    useEffect(() => {
        setPageInfo(pagination);
    }, [pagination]);

    useEffect(() => {
        setOrdersData(initialOrdersData);
    }, [initialOrdersData]);


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentOrders = ordersData; //filteredOrders.slice(indexOfFirstItem, indexOfLastItem);
    //console.log(currentOrders);
    //const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

    const handleNextPageWithCursor = () => {
        if (pageInfo.hasNextPage) {
            fetchOrdersWithCursor(null, pageInfo.endCursor);
        }
    };

    const handlePrevPageWithCursor = () => {
        if (pageInfo.hasPreviousPage) {
            fetchOrdersWithCursor(pageInfo.startCursor, null);
        }
    };

    const fetchOrdersWithCursor = debounce(async(startCursor, endCursor, query) => {

        if (!storeId) {
            storeId = localStorage.getItem("storeid");
            if (storeId) {
              setStoreId(storeId);
            }
        }
        // console.log(currPath);
        // if(currPath =='compliant'){
        //     const compliantresponse = await axios.post(`${process.env.REACT_APP_STORE_URL}/orders/compliant`,{ 
        //         storeId,
        //         searchQuery: query,
        //     }); 
        //     console.log(compliantresponse);
        // }
        let response;
        if(currPath === 'compliant') {
            response = await axios.post(`${process.env.REACT_APP_STORE_URL}/orders/compliant`, {
                storeId,
                startCursor,
                endCursor,
                itemsPerPage,
                searchQuery: query,
            });
        } else {
            response = await axios.post(`${process.env.REACT_APP_STORE_URL}/orders/list`, {
                storeId,
                startCursor,
                endCursor,
                itemsPerPage,
                searchQuery: query,
            });
        }
        
        const { orders, pagination} = response.data;
        setOrdersData(orders);
        setPageInfo(pagination);       
    }, 500);

    return (
        <div className="col-md-12 row-cols-12">
            <div className="searchbar">
                <input
                    type="text"
                    placeholder="Rechercher par ID"
                    value={searchQuery}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onPaste={handlePaste}
                />
            </div>
            <div className="table-responsive">
                <table>
                    <thead>
                        <tr className="align-items-center">
                            <th className="id-max p-2 text-center">ID</th>
                            <th className="p-2 text-center">Référence</th>
                            <th className="p-2 text-center">Nom et prénom</th>
                            <th className="carrier min-width-25 p-2 text-center">Transporteur</th>
                            <th className="p-2 min-width-25 text-center">Type de paiement</th>
                            <th className="p-2 text-center">Total</th>
                            {(currPath === "noncompliant" || currPath === "compliant") && (
                                <th className="p-2 text-center">Email</th>
                            )}
                            <th className="text-center return p-2">Magasin</th>
                            <th className="text-center date p-2">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentOrders.length > 0 ? (
                            currentOrders.map((order, index) => (
                                <tr key={index} onClick={() => goToOrderDetail(order.gid_order)} className={`${currPath} orders-list align-items-center`}>
                                    <td className="id-max p-2 text-center">{order.id_order}</td>
                                    <td className="p-2 text-center">{order.reference}</td>
                                    <td className="p-2 text-center">{order.customername}</td>
                                    <td className="p-2 text-center">{order.carriername}</td>
                                    {order.payment === "" ? (
                                        <td className="p-2 min-width-25 text-center">-</td>
                                    ) : (
                                        <td className="p-2 min-width-25 text-center">{order.payment}</td>
                                    )}
                                    <td className="p-2 text-center">{order.total} €</td>
                                    {(currPath === "noncompliant" || currPath === "compliant") && (
                                        <td className="p-2 text-center">{order.employee_email}</td>
                                    )}
                                    <td className="p-2 text-center">{order.locationname}</td>
                                    <td className="text-center date p-2">{order.added_date}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={8} className="text-center">Aucune commande trouvée</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            { pageInfo && pageInfo.hasNextPage === true && currPath !== 'noncompliant' && currPath !== 'compliant' &&
            <div className="pagination">
                <ul className="pagination-list">
                    <li className={`page-item ${!pageInfo.hasPreviousPage ? 'disabled' : ''}`}>
                        <button onClick={() => handlePrevPageWithCursor(pageInfo.startCursor, null)} className="page-link">
                            Previous page
                        </button>
                    </li>
                    <li className={`page-item ${!pageInfo.hasNextPage ? 'disabled' : ''}`}>
                        <button onClick={() => handleNextPageWithCursor(null, pageInfo.endCursor)} className="page-link">
                            Next page
                        </button>
                    </li>
                </ul>
            </div>
            }
        </div>
    );
}
